












import AppDrawer from '@/components/AppDrawer.vue'
import AppToolbar from '@/components/AppToolbar.vue'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'AuthenticatedLayout',
  components: {
    AppDrawer,
    AppToolbar
  }
})
export default class AuthenticatedLayout extends Vue {
  drawer = false
}
