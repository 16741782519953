



















































































































import WalletIndicator from './WalletIndicator.vue'
import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'

@Component({
  name: 'AppToolbar',
  components: {
    WalletIndicator
  }
})
export default class AppToolbar extends Vue {
  @Action('isAccountWhitelistedValidator', { namespace: 'wallet' }) isAccountWhitelistedValidator!: any

  wallet = {
    address: '0x0000-0000',
    signedInWith: 'metamask',
    active: true
  }

  isValidator = false

  async mounted (): Promise<void> {
    this.isValidator = await this.isAccountWhitelistedValidator()
  }
}
