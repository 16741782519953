
























































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component({
  name: 'AppDrawer'
})
export default class AppDrawer extends Vue {
  @Prop()
  drawer!: boolean

  get open (): boolean {
    return this.drawer
  }

  set open (val: boolean) {
    this.$emit('toggle-app-drawer', val)
  }
}
