


















import { Component, Prop, Vue } from 'vue-property-decorator'

import { Action } from 'vuex-class'
import type { Wallet } from '@/types/wallet'
import { mapGetters } from 'vuex'
const namespace = 'auth'
@Component({
  name: 'WalletIndicator',
  computed: {
    ...mapGetters('wallet', ['userAccount'])
  }
})
export default class WalletIndicator extends Vue {
   @Action('getToken', { namespace }) getToken!: (token: string | null) => Promise<void>

 @Prop({
   required: true
 })
 wallet!: Wallet

 async handleAuth (): Promise<void> {
   // await this.getToken(null)
   // await this.$router.push({ name: 'authSignIn' })
 }

 formatText (text: string): string {
   const len = text.length
   return text.slice(0, 13) + '...' + text.slice(len - 3, len)
 }
}
